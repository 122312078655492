// src/components/Navbar.js
import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const menuRef = useRef(null);
  const location = useLocation(); // To track route changes

  // Toggle the menu when the hamburger is clicked
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Handle scrolling
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 100); // Adjust this value to your liking

      // If the menu is open and user scrolls, close the menu
      if (menuOpen) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menuOpen]); // menuOpen is the only dependency

  // Handle click outside to close the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Scroll to top when navigating to a new page
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setMenuOpen(false); // Close menu after navigating
  }, [location]);

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      {/* Logo and text */}
      <Link to="/" className="navbar-logo-link" onClick={() => setMenuOpen(false)}>
        <div className="navbar-logo-container">
          <img src="/NN-logo-1.svg" alt="NN Logo" className="navbar-logo" />
          <div className="navbar-logo-text">
            <span className="nordic">Nordic</span>
            <span className="numbers">Numbers</span>
          </div>
        </div>
      </Link>

      {/* Navbar links */}
      <div ref={menuRef} className={`navbar-links ${menuOpen ? 'open' : ''}`}>
        <NavLink
          to="/"
          className={({ isActive }) => `navbar-link${isActive ? ' active-link' : ''}`}
          onClick={() => setMenuOpen(false)}
        >
          Home
        </NavLink>
        <NavLink
          to="/company"
          className={({ isActive }) => `navbar-link${isActive ? ' active-link' : ''}`}
          onClick={() => setMenuOpen(false)}
        >
          Company
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) => `navbar-link${isActive ? ' active-link' : ''}`}
          onClick={() => setMenuOpen(false)}
        >
          Contact
        </NavLink>
        <a href="https://www.linkedin.com/company/nordic-numbers/" target="_blank" rel="noopener noreferrer">
          <img src="/basil_linkedin-outline.svg" alt="LinkedIn" className="linkedin-icon" />
        </a>
      </div>

      {/* Hamburger menu */}
      <div className="hamburger-menu" onClick={toggleMenu}>
        <span className="hamburger-bar"></span>
        <span className="hamburger-bar"></span>
        <span className="hamburger-bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
