import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './Home.css';
import WordCarousel from '../components/WordCarousel';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Home = () => {
    const [backgroundImage1, setBackgroundImage1] = useState('Container_1_background.png');
    const [backgroundImage6, setBackgroundImage6] = useState('Container_6_background.png');

    useEffect(() => {
      const updateBackground = () => {
        // For Container 1
        if (window.innerWidth <= 1200) {
          setBackgroundImage1('Container_1_background_small.png');
        } else {
          setBackgroundImage1('Container_1_background.png');
        }

        // For Container 6
        if (window.innerWidth <= 1200) {
          setBackgroundImage6('Container_6_background_small.png');
        } else {
          setBackgroundImage6('Container_6_background.png');
        }
      };

    // Initial check
    updateBackground();

    // Update on resize
    window.addEventListener('resize', updateBackground);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', updateBackground);
  }, []);

  return (
    <div>
      <Helmet>
      {/* Primary SEO Meta Tags */}
      <title>Nordic Numbers | Causal Analysis for Business Insights</title>
      <meta
        name="description"
        content="Nordic Numbers provides cutting-edge causal analysis to help businesses understand not just what might happen, but why. If you understand why, you can purposefully induce the results you desire."
      />
      <meta
        name="keywords"
        content="causal analysis, business insights, data analytics, machine learning, predictive analysis, data-driven decisions"
      />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content="Nordic Numbers | Causal Analysis for Business Insights" />
      <meta
        property="og:description"
        content="We help businesses use causal analysis to make data-driven decisions that not only predict outcomes but explain why they happen."
      />
      <meta property="og:image" content="https://www.nordicnumbers.no/bigger_logo.png" />
      <meta property="og:url" content="https://www.nordicnumbers.no" />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta name="twitter:title" content="Nordic Numbers | Causal Analysis and Data-Driven Insights" />
      <meta
        name="twitter:description"
        content="At Nordic Numbers, we use causal analysis to help businesses make informed, data-driven decisions. Understand not just what might happen, but why."
      />
      <meta name="twitter:image" content="https://www.nordicnumbers.no/bigger_logo.png" />
      <meta name="twitter:card" content="summary_large_image" />

      {/* Structured Data */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Nordic Numbers",
            "url": "https://www.nordicnumbers.no",
            "logo": "https://www.nordicnumbers.no/small_logo.png",
            "description": "Nordic Numbers provides causal data analysis services, helping businesses understand not just what might happen but why.",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+47 948 24 472",
              "email": "jone@nordicnumbers.no",
              "contactType": "Customer Service"
            },
            "sameAs": [
              "https://www.linkedin.com/company/nordic-numbers/"
            ]
          }
        `}
      </script>
    </Helmet>
      
      <Navbar />
      {/* Container 1 - Carousel */}
      <div className="container-1">
        <img src={backgroundImage1} alt="Nordic Numbers background" className="responsive-bg" />
        <div className="content-container">
          <div className="left-container">
            <h1 className="main-heading">
              We help you understand
            </h1>
          </div>
          <div className="right-container">
            <WordCarousel />
          </div>
        </div>
      </div>
      {/* Container 2 - Nordic Numbers and spheres */}
      <div className="container-2">
        <div className="content-box">
          <div className="text-container">
            <h2 className="nordic-numbers">Nordic Numbers</h2>
            <p className="description">
              are specialists in the data toolbox of tomorrow.
              <br /><br />
              With our expertise in causal machine learning, we go beyond conventional methods that only tell you <span className="highlight">what</span> might happen. We provide insights into <span className="highlight">why</span> it happens.
              <br /><br />
              When you understand <span className="highlight">why</span>, you can purposefully achieve the results you desire.
            </p>
          </div>
          <div className="image-container">
            <img src="spheres-1.png" alt="Visual representation of data insights" className="illustration" />
            <img src="spheres-moving.png" alt="Animated sphere illustrating data" className="moving-sphere sphere-1" />
            <img src="spheres-moving.png" alt="Animated sphere 2" className="moving-sphere sphere-2" />
            <img src="spheres-moving.png" alt="Animated sphere 3" className="moving-sphere sphere-3" />
            <img src="spheres-moving.png" alt="Animated sphere 4" className="moving-sphere sphere-4" />
            <img src="spheres-moving.png" alt="Animated sphere 5" className="moving-sphere sphere-5" />
            <img src="spheres-moving.png" alt="Animated sphere 6" className="moving-sphere sphere-6" />
          </div>
        </div>
      </div>
      {/* Container 3 - Contact Button */}
      <div className="container-3">
        <Link to="/contact" className="contact-button">Contact Page</Link>
      </div>
      {/* Container 4 - Infographics */}
      <div className="container-4">
        <div className="info-container">
          <img src="info_container_1.svg" alt="Infographic 1" className="info-image" />
          <img src="Vector_arrow.svg" alt="Arrow pointing to next infographic" className="arrow arrow-1-to-2" />
        </div>
        <div className="info-container">
          <img src="info_container_2.svg" alt="Infographic 2" className="info-image" />
          <img src="Vector_arrow.svg" alt="Arrow 2 to 3" className="arrow arrow-2-to-3" />
        </div>
        <div className="info-container">
          <img src="info_container_3.svg" alt="Infographic 3" className="info-image" />
          <img src="Vector_arrow.svg" alt="Arrow 3 to 4" className="arrow arrow-3-to-4" />
        </div>
        <div className="info-container">
          <img src="info_container_4.svg" alt="Infographic 4" className="info-image" />
        </div>
      </div>
      {/* Container 6 */}
      <div className="container-6">
        <img src={backgroundImage6} alt="Nordic Numbers background" className="responsive-bg" />
        <div className="content-wrapper">
          <h1 className="headline">What if I don't have vast amounts of data?</h1>
          <p className="subtext">
            If your businesses is still in the early stages and do not yet have sufficient data, we still recommend you to get in touch.
            <br /><br />
            We can guide your future data-gathering process, effectively turning your business into a scientific powerhouse, continuously generating actionable insights that help you navigate the forthcoming challenges.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
