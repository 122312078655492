// src/components/Footer.js
import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4 className="footer-title">Information</h4>
          <ul className="footer-links">
            <li><a href="/" className="footer-link">Home</a></li>
            <li><a href="/company" className="footer-link">About</a></li>
            <li><a href="/contact" className="footer-link">Contact</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4 className="footer-title">Email</h4>
          <p className="footer-text">jone@nordicnumbers.no</p>
        </div>
        <div className="footer-section">
          <h4 className="footer-title">Office</h4>
          <p className="footer-text">+47 948 24 472</p>
          <p className="footer-text">Michael Krohns Street 8</p>
          <p className="footer-text">5057 Bergen</p>
          <p className="footer-text">Norway</p>
        </div>
        <div className="footer-section">
          <h4 className="footer-title">Social Media</h4>
          <a href="https://www.linkedin.com/company/nordic-numbers/" target="_blank" rel="noopener noreferrer">
            <img src="/basil_linkedin-outline.svg" alt="LinkedIn" className="footer-icon" />
          </a>
        </div>
        <div className="footer-section-logo">
          <Link to="/" className="footer-logo-link">
            <div className="footer-logo-container">
              <img src="/NN-logo-1.svg" alt="NN Logo" className="footer-logo" />
              <div className="footer-logo-text">
                <span className="nordic">Nordic</span>
                <span className="numbers">Numbers</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
