// src/components/WordCarousel.js
import React, { useState, useEffect } from 'react';
import './WordCarousel.css';

const words = ["WHY", "THE CAUSE", "THE REASON", "THE CONSEQUENCES", "HOW TO INTERVENE"];

const WordCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2500); // Change word every 2 seconds

    return () => clearInterval(interval);
  }, []);

  // Positions the elements relative to the currentIndex
  const getWordClass = (index) => {
    const relativeIndex = (index - currentIndex + words.length) % words.length;
    switch (relativeIndex) {
      case 0:
        return "position-middle";
      case 1:
        return "position-above";
      case 2:
        return "position-above-far";
      case 3:
        return "position-below-far";
      case 4:
        return "position-below";
      default:
        return "";
    }
  };

  return (
    <div className="word-carousel">
      {words.map((word, index) => (
        <div key={index} className={`carousel-word ${getWordClass(index)}`}>
          {word}
        </div>
      ))}
    </div>
  );
};

export default WordCarousel;
